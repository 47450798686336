<template>
  <video
    autoplay
    muted
    loop
    preload="auto"
  >
    <source
      v-if="videoSrc"
      :src="videoSrc"
    >
    Your browser does not support the video tag.
  </video>
</template>

<script>
import {getAssetFromDB} from "@/utils/indexedDB";

export default {
  props: {
    video_url: String
  },
  data() {
    return {
      videoSrc: null
    }
  },
  async mounted() {
    this.videoSrc = await getAssetFromDB(this.video_url)
  }
}
</script>
