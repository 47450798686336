import cdnUrl from './imageUrl'

let db;

export function initDB() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open('slideshow', 1);

      request.onerror = (e) => {
        console.error(e);
        reject(e)
      };

      request.onsuccess = e => {
        db = e.target.result
        resolve(db)
      };

      request.onupgradeneeded = event => {
        const db = event.target.result;
        db.createObjectStore("assets", {keyPath: 'id'});
      };
    });
}

function getAssetsUrl(slide) {
  if (slide.type === 'images') {
    return cdnUrl(slide.layers[0].image)
  } else {
    return slide.video_url
  }
}

export async function saveSlides(slides) {
  const objectStore = db.transaction(["assets"], 'readonly').objectStore('assets')

  slides.forEach((slide) => {
    const assetsUrl = getAssetsUrl(slide);

    const req = objectStore.count(assetsUrl);

    req.onsuccess = async (e) => {
      const cursor = e.target.result;

      if (cursor) {
        slide.loaded = true;
      } else {
        try {
          console.warn('Downloading asset', assetsUrl)
          const videoRequest = await fetch(assetsUrl)
          const blob = await videoRequest.blob();

          if (blob.size === 0) {
            throw new Error(`Empty blob - ${JSON.stringify(videoRequest)}`)
          }

          console.warn('Adding asset to db', assetsUrl)
          const assetsStore = db.transaction(["assets"], 'readwrite').objectStore('assets')
          const addRequest = assetsStore.add({id: assetsUrl, blob})
          addRequest.onsuccess = () => slide.loaded = true;
        } catch (e) {
          console.error(`Something went wrong when fetching ${assetsUrl}`, e)
        }
      }
    };

    req.onerror = () => {
      slide.loaded = false;
    };
  })
}

export function getAssetFromDB(id) {
  return new Promise((resolve, reject) => {
    const store = db.transaction(["assets"], 'readonly').objectStore('assets')
    const req = store.get(id);

    req.onerror = (e) => {
      reject(e)
    };

    req.onsuccess = (event) => {
      resolve(URL.createObjectURL(event.target.result.blob));
    };
  });
}
