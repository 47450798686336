<template>
  <div class="bars">
    <div
      v-for="bar of bars"
      :key="bar.id"
      class="bar"
      :class="bar.className"
      :style="bar.style"
    />
    <transition @before-leave="leaveHandler">
      <slot />
    </transition>
  </div>
</template>

<script>
import getConfig from "@/config";
const config = getConfig();
const WIPE_DURATION = 750;
const ANIMATION_DELAY = 170;
const NUMBER_OF_BARS = 3;
const COLORS = config.transitionColors;


export default {
  name: 'QTransition',
  data() {
    return {
      bars: [],
    }
  },
  methods: {
    leaveHandler() {
      this.useRandomDirection()
    },
    getRandomQColors() {
      const shuffled = COLORS.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, NUMBER_OF_BARS);
    },
    resetBars() {
      this.bars = []
    },
    useRandomDirection() {
      const wipeColors = this.getRandomQColors()
      const bars = [];
      const side = Math.random() <= 0.5 ? 'left' : 'right';

      for (let i = 0; i < NUMBER_OF_BARS; i++) {
        bars.push({
          id: `bar${i}`,
          style: {
            "background-color": wipeColors[i],
            "animation-name": `slide-${side}`,
            "animation-duration": `${WIPE_DURATION}ms`,
            "animation-delay": `${i * ANIMATION_DELAY}ms`,
            "z-index": 10 + i
          },
          className: `bar-${side}`
        })
      }

      this.bars = bars

      setTimeout(() => {
        this.resetBars();
      }, WIPE_DURATION  + (NUMBER_OF_BARS * ANIMATION_DELAY));
    },
  },
}
</script>

<style lang="scss">
.bars {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bar {
  position: fixed;
  z-index: 100;
  top: 0;
  height: 100%;
  width: 0;
}

.bar-left {
  right: 0;
  left: 100%;
}

.bar-right {
  left: 0;
  right: 100%;
}


@keyframes slide-left {
  30% {
    width: 100vw
  }
  50% {
    left: 0;
    width: 100vw
  }
  100% {
    width: 0;
    left: 0;
    right: 100%;
  }
}


@keyframes slide-right {
  30% {
    left: 0;
    width: 100vw
  }
  50% {
    left: 0;
    width: 100vw
  }
  100% {
    width: 0;
    right: 0;
    left: 100%;
  }
}

</style>
