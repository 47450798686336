import BeachHouseLogo from './assets/2024BH.jpg'
import SummerIslandLogo from './assets/summer-island.jpg'

const STATIONS = {
  qmusic_be: {
    apiBaseUrl: 'https://api.qmusic.be',
    defaultImage: BeachHouseLogo,
    transitionColors: ['#ED3624', '#00bfb3', '#f9d423', '#d26ee6', '#97d700', '#5bc2e7']
  },
  joe_fm: {
    apiBaseUrl: 'https://api.joe.be',
    defaultImage: SummerIslandLogo,
    transitionColors: ['#1e64c8', '#14328c'],
    rotate: true,
  },
}

export default function () {
  const urlParams = new URLSearchParams(window.location.search)

  return {
    ...Object.fromEntries(urlParams),
    ...STATIONS[urlParams.get('station')],
    socketUrl: `https://wall.dario.fm/${urlParams.get('screenId')}`,
    apiEndpoints: {
      screens: `${STATIONS[urlParams.get('station')].apiBaseUrl}/2.7/screens/${urlParams.get('screen')}`,
    },
  }
}
