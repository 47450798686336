<template>
  <img
    v-if="imageSrc"
    :src="imageSrc"
  >
</template>

<script>
import cdnUrl from '../utils/imageUrl'
import {getAssetFromDB} from "@/utils/indexedDB";

export default {
  props: {
    layers: Array
  },
  data() {
    return {
      imageSrc: null
    }
  },
  async mounted() {
     this.imageSrc = await getAssetFromDB(cdnUrl(this.layers[0].image))
  }
}
</script>
